import React from "react";
import { Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import duolingoNinjaVocabularyList from "../duolingo-ninja-vocabulary-list.png";

import AppMetaDataContext from "../context/AppMetaDataContext";

function DownloadVocabularyWithExtension() {
  const metaData = React.useContext(AppMetaDataContext);

  return (
    <div>
      <Card className="border-0 mb-5">
        <Card.Body>
          <Card.Text>
            <h2 className="my-5">
              Use The Lingo Champion Browser Extension To Download The Words
            </h2>

            <center>
              <img
                src={duolingoNinjaVocabularyList}
                alt="Duolingo vocabulary list from Duolingo Ninja"
                className="img-fluid my-3"
                // set maxwidth
                style={{ maxHeight: "500px" }}
              />
            </center>

            <p>
              The Lingo Champion browser extension works both with Google Chrome
              and Microsoft Edge. It lets you see, practice, and download your
              Duolingo vocabulary.
            </p>
            {/* <ol>
              <li>
                Go to the{" "}
                <a
                  href="https://chrome.google.com/webstore/detail/duolingo-ninja/cdiecbgkdbkloiniekbfblbdbjgehpgb"
                  target="_blank"
                >
                  Google Chrome Web Store
                </a>
              </li>
              <li>
                Install the <strong>Duolingo Ninja extension</strong>
              </li>
              <li>Make sure you are also logged in to Duolingo.com</li>
              <li>Open the browser extension</li>
              <li>Click on the gear icon or "more options"</li>
              <li>Sync the words from Duolingo</li>
            </ol> */}
            <ol>
              <li>
                Sign up for free for{" "}
                <a href="https://lingochampion.com?utm_source=duolingo_ninja&utm_medium=duolingo_ninja_download_vocabulary_page&utm_campaign=duolingo_ninja_download_vocabulary_page">
                  <strong>Lingo Champion</strong>
                </a>
              </li>
              <li>
                Install{" "}
                <a href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff">
                  the extension
                </a>
              </li>
              <li>Make sure you are also logged in to Duolingo.com</li>
              <li>
                Navigate to the{" "}
                <a href="https://www.duolingo.com/practice-hub/words">
                  words page on Duolingo
                </a>
              </li>
              <li>Click on the "Import words" button on top of the page</li>
              <li>
                {" "}
                Use the words however you please:
                <ul>
                  <li>Download the words</li>
                  <li>Practice the words with flashcards in Lingo Champion</li>
                  <li>
                    Translate parts of websites using the words as you browse
                    (with the browser extension)
                  </li>
                  <li>
                    Use the words to filter out texts (news, stories, books) for
                    your level in Lingo Champion
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              You can now see the full list of your Duolingo vocabulary and copy
              it wherever you want.
            </p>
            <p>
              <i>
                Note that Duolingo does not provide words for every single
                course. If there are no words on the page, it means they don't
                provide it.
              </i>
            </p>

            <p>
              There are also word lists for some languages on the Duolingo Ninja
              website:
              <ul>
                <li>
                  <LinkContainer to="/duolingo-french-vocabulary-list">
                    <a>Duolingo French vocabulary list</a>
                  </LinkContainer>
                </li>
                <li>
                  <LinkContainer to="/duolingo-german-vocabulary-list">
                    <a>Duolingo German vocabulary list</a>
                  </LinkContainer>
                </li>
                <li>
                  <LinkContainer to="/duolingo-hindi-vocabulary-list">
                    <a>Duolingo Hindi vocabulary list</a>
                  </LinkContainer>
                </li>
                <li>
                  <LinkContainer to="/duolingo-italian-vocabulary-list">
                    <a>Duolingo Italian vocabulary list</a>
                  </LinkContainer>
                </li>
                <li>
                  <LinkContainer to="/duolingo-japanese-vocabulary-list">
                    <a>Duolingo Japanese vocabulary list</a>
                  </LinkContainer>
                </li>
                <li>
                  <LinkContainer to="/duolingo-korean-vocabulary-list">
                    <a>Duolingo Korean vocabulary list</a>
                  </LinkContainer>
                </li>
                <li>
                  <LinkContainer to="/duolingo-russian-vocabulary-list">
                    <a>Duolingo Russian vocabulary list</a>
                  </LinkContainer>
                </li>
                <li>
                  <LinkContainer to="/duolingo-spanish-vocabulary-list">
                    <a>Duolingo Spanish vocabulary list</a>
                  </LinkContainer>
                </li>
              </ul>
            </p>

            {/* <Row
                style={{ marginBottom: "4%", marginTop: "5%" }}
                className="mb-3"
              >
                <Col xs={{ span: 12 }} lg={{ span: 4 }}></Col>
                <Col xs={{ span: 12 }} lg={{ span: 8 }}></Col>
              </Row> */}
          </Card.Text>
        </Card.Body>
      </Card>

      <hr className="mb-5" />
    </div>
  );
}

export default DownloadVocabularyWithExtension;
