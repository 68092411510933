import React, { useState, useEffect } from "react";
import { Card, Alert, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ReactGA from "react-ga4";

import duolingoNinjaVocabularyList from "../duolingo-ninja-vocabulary-list.png";

import AppMetaDataContext from "../context/AppMetaDataContext";

function registerDownloadClick() {
  ReactGA.event({
    category: "navigation",
    action: "click_lingo_champion_extension_button",
    label: "lingo_champion_extension_button",
  });
}

function DownloadVocabularyWithExtension() {
  const metaData = React.useContext(AppMetaDataContext);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-V2T4J6Y9YR");
      window.GA_INITIALIZED = true;
    }
  }, []);

  if (show) {
    return (
      <center>
        <Alert
          variant="light"
          className="mt-4 mb-4"
          style={{ border: "1px gray solid", maxWidth: "800px" }}
          onClose={() => setShow(false)}
          dismissible
        >
          <Card className="border-0">
            <Card.Body className="mx-1" style={{ textAlign: "left" }}>
              <Card.Title
                style={{ fontWeight: "normal", fontSize: "2.4em" }}
                className="my-4 text-center"
              >
                Download <strong>your</strong>{" "}
                <span style={{ color: "#58cc02", fontWeight: "bold" }}>
                  duolingo
                </span>{" "}
                vocabulary
              </Card.Title>

              <Card.Text>
                The Lingo Champion browser extension is compatible with both
                Google Chrome and Microsoft Edge. It allows you to download and
                practice your Duolingo vocabulary.
              </Card.Text>

              <div className="text-center">
                <Button
                  variant="success"
                  className="my-2"
                  href="https://lingochampion.com?utm_source=duolingo_ninja&utm_medium=duolingo_ninja_download_vocabulary_page&utm_campaign=duolingo_ninja_download_vocabulary_page"
                  onClick={() => registerDownloadClick()}
                >
                  Get it for free
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Alert>
      </center>
    );
  }

  return (
    <Button onClick={() => setShow(true)} variant="light">
      Show download instructions
    </Button>
  );
}

export default DownloadVocabularyWithExtension;
